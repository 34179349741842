var btnPrevArrow = '<a href="javascript:void(0);" type="button" class="slick-prev slick-arrow"><span class="icon-arrow-slick"></span></a>';
var btnNextArrow = '<a href="javascript:void(0);" type="button" class="slick-next slick-arrow"><span class="icon-arrow-slick"></span></a>';
var screen_xs_max = 767;

$('.paragraph-nav').on('click', function () {
  $('.paragraph-inner').toggleClass('slide-paragraph');
});

document.addEventListener("DOMContentLoaded", function() {
  const sweetScroll = new SweetScroll({});
}, false);

//SINAL DE + E - NO FAQ
function toggleIcon(e) {
    $(e.target)
        .prev('.panel-heading')
        .find(".more-less")
        .toggleClass('glyphicon-plus glyphicon-minus');
}
$('.panel-group').on('hidden.bs.collapse', toggleIcon);
$('.panel-group').on('shown.bs.collapse', toggleIcon);


if($(window).width() < 768)
{
  $("footer .sobre .content").removeClass("in");
  $("footer .endereco .content").removeClass("in");
  $("footer .contato .content").removeClass("in");

  function toggleIcon(e) {
      $(e.target)
          .prev('.title')
          .find(".more-less")
          .toggleClass('glyphicon-plus glyphicon-minus');
  }
  $('.rodape').on('hidden.bs.collapse', toggleIcon);
  $('.rodape').on('shown.bs.collapse', toggleIcon);

}

if($('#cadastro').length) {
  $('.navbar-default .navbar-nav li a').click(function(e){
    e.preventDefault();
    var href = jQuery(this).attr('href');
    window.location = '/#' + href.substr(1);
  });
}


//Tooltips
$(document).ready(function(){
    $('[data-toggle="tooltip"]').tooltip();
});

$('body').scrollspy({ target: '#navbar' });

$('.banner-slider').slick({
  arrows: true,
  prevArrow: btnPrevArrow,
  nextArrow: btnNextArrow,
  autoplay: true,
  speed: 2000,
  dots: true,
});

$('.banner-mobile-slider').slick({
  arrows: true,
  prevArrow: btnPrevArrow,
  nextArrow: btnNextArrow,
  autoplay: true,
  speed: 2000,
  dots: true,
});

$('.wfr-slider').slick({
  slidesToShow: 4,
  slidesToScroll: 4,
  arrows: false,
  responsive: [
    {
      breakpoint: 480,
      settings: {
        arrows: false,
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoPlaySpeed: 2000
      }
    }
  ]
});

$('.funcionamento-slider').slick({
  slidesToShow: 2,
  slidesToScroll: 4,
  arrows: false,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        arrows: false,
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoPlaySpeed: 2000
      }
    }
  ]
});

$('.parceiros-slider').slick({
  slidesToShow: 4,
  slidesToScroll: 4,
  arrows: false,
  responsive: [
    {
      breakpoint: 480,
      settings: {
        arrows: false,
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoPlaySpeed: 2000
      }
    }
  ]
});

// Back to top
$(window).scroll(function () {
  var scroll = $(this).scrollTop();

  if(scroll > 300) {
    $('.btn-top').css('display', 'block');
  } else {
    $('.btn-top').css('display', 'none');
  }

  if ($(window).width() < screen_xs_max && scroll > 150) {
    $('.top-navbar').addClass('navbar-scroll');
  } else {
    $('.top-navbar').removeClass('navbar-scroll');
  }
});


$(document).ready(function () {

  //EXIBE OS CAMPOS DE AGENCIA
  $('#rating-check').on('change',function(){
    if ($(this).is(':checked')) {
      $('#hidden-field1').append("<input type='text' placeholder='Agência' class='form-control' id='agencia'>");
    } else {
      $('#agencia').remove();
    }

  });
  //EXIBE OS CAMPOS DE AUDITOR
  $('#auditada').on('change',function(){
    if ($(this).is(':checked')) {
      $('#hidden-field2').append("<input type='text' placeholder='Nome do auditor' class='form-control' id='auditor'>");
    } else {
      $('#auditor').remove();
    }

  });

});


//EFEITOS FORMULARIO

    $.support.placeholder = (function(){
        var i = document.createElement('input');
        return 'placeholder' in i;
    })();

    if($.support.placeholder) {
        $('.form-label').each(function(){
            $(this).addClass('js-hide-label');
        });

        $('.form-group').find('input, textarea').on('keyup blur focus', function(e){

            var $this = $(this),
                $parent = $this.parent().find("label");

            if (e.type == 'keyup') {
                if( $this.val() == '' ) {
                    $parent.addClass('js-hide-label');
                } else {
                    $parent.removeClass('js-hide-label');
                }
            }
            else if (e.type == 'blur') {
                if( $this.val() == '' ) {
                    $parent.addClass('js-hide-label');
                }
                else {
                    $parent.removeClass('js-hide-label').addClass('js-unhighlight-label');
                }
            }
            else if (e.type == 'focus') {
                if( $this.val() !== '' ) {
                    $parent.removeClass('js-unhighlight-label');
                }
            }
        });
    }


// Masks
$("#telefone").keydown(function(e){
  var tamanho = $(this).val().length;

  if(tamanho < 11 || ( e.keyCode == 8 && tamanho < 11 ) ){
    $(this).mask("(99) 9999-9999");
  } else {
    $(this).mask("(99) 9999-99999");
  }
});
$("#telefone").mask("(00) 0000-0000");

$("#cnpj").on("keyup", function(e)
{
    $(this).val(
        $(this).val()
        .replace(/\D/g, '')
        .replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, "$1 $2 $3/$4-$5"));
});


//VALIDAÇÃO FORMULARIO
function isBlank(value) {
  return (value === null || value === "");
}

function isValidEmail(value) {
  var regex = /([\w-\.]+)@((?:[\w]+\.))([a-zA-Z]{2,4})/;
  return regex.test(value);
}

function addErrorClass(input) {
  $('input').addClass('validation-error');
}

function removeErrorClass() {
  $('input').removeClass('validation-error');
}

function addValidationMessage(message, type) {
  var validationClass = (type == 'success') ? 'alert alert-success show' : 'alert alert-danger show';
  $('.validation-message').text(message).addClass(validationClass);
}

function addValidationScreen() {
  $('#contact').css({display:"none"});
  $('#message').css({display:"block"});
}

function addValidationScreenCad() {
  $('#cadastro').css({display:"none"});
  $('#message-cad').css({display:"block"});
}

function removeValidationMessage() {
  $('.validation-message').text('');
}

function clearInputs() {
  $('.form').trigger("reset");
}

function validateInput(input) {
  value = $(input).val();
  type = $(input).attr('type').toString();

  switch (type) {
    case 'text':
      if(! isBlank(value)) {
        return true;
      } else {
        addErrorClass(input);
        addValidationMessage('Preencha todos os campos.');
        return false;
      }
      break;
    case 'email':
      if(! isBlank(value) && isValidEmail(value)) {
        return true;
      } else {
        addErrorClass(input);
        addValidationMessage('Email inválido');
        return false;
      }
      break;
    default:
      return 'Erro, ação inválida.';
  }
}

//FORM IO
$('#sendSubmission').on('click', function(e) {
  e.preventDefault();

  // Remove the validation class from previous submits of all inputs
  removeErrorClass();

  var name  = $('#nome');
  var email = $('#email');
  var phone = $('#telefone');
  var empresa = $('#empresa');
  var mensagem = $('#mensagem');



  if(validateInput(name) && validateInput(email) && validateInput(phone) && validateInput(empresa)) {
    var inputsObj = {
      data: {
        nome: $('#nome').val(),
        email: $('#email').val(),
        telefone: $('#telefone').val(),
        empresa: $('#empresa').val(),
        mensagem: $('#mensagem').val(),
      }
    };

    var settings = {
      "async": true,
      "crossDomain": true,
      "url": "https://yboguotqbtfhjvj.form.io/contato/submission",
      "method": "POST",
      "headers": {
        "content-type": "application/json"
      },
      "processData": false,
      "data": JSON.stringify(inputsObj)
    };

    $.ajax(settings).done(function (response) {
      dataLayer.push({
        'event': 'leadSent',
        'formName': 'Contato'
      });

      addValidationScreen();
      clearInputs();
    });
  }
});


//FORM IO CADASTRO

$('#submit-btn').on('click', function(e) {
  e.preventDefault();

  // Remove the validation class from previous submits of all inputs
  removeErrorClass();

  var name  = $('#nome');
  var email = $('#email');
  var phone = $('#telefone');
  var cnpj = $('#cnpj');



  if(validateInput(name) && validateInput(email) && validateInput(phone) && validateInput(cnpj)) {
    var inputsObj = {
      data: {
        nome: $('#nome').val(),
        email: $('#email').val(),
        telefone: $('#telefone').val(),
        cnpj: $('#cnpj').val()
      }
    };

    var settings = {
      "async": true,
      "crossDomain": true,
      "url": "https://yboguotqbtfhjvj.form.io/contatonovo/submission",
      "method": "POST",
      "headers": {
        "content-type": "application/json"
      },
      "processData": false,
      "data": JSON.stringify(inputsObj)
    };

    $.ajax(settings).done(function (response) {
      dataLayer.push({
  	    'event': 'leadSent',
        'formName': 'Cadastro'
      });

      addValidationScreenCad();
      clearInputs();
    });
  }
});
